import React from 'react';
import cellphone from '../assets/12.png';
import office2 from '../assets/office2.png';
import 'animate.css';

const Analytics = () => {
  return (
    <div className='w-full bg-black py-16 px-4 relative'> {}
      {}
      <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${office2})`, opacity: '0.1' }} />
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        {/* Cellphone image */}
        <div className='flex justify-center'>
          <img className='w-[500px] my-4 mr-2 animate__animated animate__bounce animate__slow' src={cellphone} alt='/' />
        </div>
        {/* Text content */}
        <div className='flex flex-col justify-center'>
          <h1 className='md:text-5xl sm:text-3xl text-2xl text-[#ffffff] font-bold py-2'>Join the Spotlight and Amplify Your Brand With Us!</h1>
          <p className='sm:text-1xl text-[#f9fc3f] font-bold '>Fueling Growth, Inspiring Change: Digital Creative Solutions</p>
          <p className='text-[#ffffff]'>
            AVIETHO DIGITAL MARKETING is your partner in unleashing the full potential of your brand!
          </p>
          <button className='bg-[#ffffff] text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
