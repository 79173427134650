import React from 'react';

import office2 from '../assets/program.mp4';
import 'animate.css';

const Analytics = () => {
  return (
    <div className='w-full bg-black py-16 px-4 relative'> {}
      {}
      <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${office2})`, opacity: '0.1' }} />
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        {/* Cellphone image */}
        <div className='flex justify-center'>
        <video className='w-[500px] my-4 mr-2' autoPlay loop muted style={{ opacity: '0.4' }}>
  <source src={require('../assets/program.mp4')} type='video/mp4' />
  Your browser does not support the video tag.
</video>


        </div>
        {/* Text content */}
        <div className='flex flex-col justify-center'>
          <h1 className='md:text-5xl sm:text-3xl text-1xl text-[#ffffff] font-bold py-1'>Website Design and Development</h1>
          <p className='sm:text-1xl text-[#f9fc3f] font-bold '>Fueling Growth, Inspiring Change: Digital Creative Solutions</p>
          <p className='text-[#ffffff93] text-sm'>
         Aviathon web development services go beyond aesthetics; we create visually stunning and technically sound websites that serve as powerful tools for your digital presence. Whether it's a sleek corporate site or a dynamic e-commerce platform, our team ensures a seamless user experience that leaves a lasting impression.
          </p>
          <h1 className='md:text-3xl sm:text-3xl text-1xl text-[#ffffff] font-bold py-1'>IT Consultancy</h1>
          <p className='text-[#ffffff93] text-sm'>
  Partner with Aviathon for strategic IT consultancy services that pave the way for your digital transformation. Our experts work closely with you to understand your business goals, offering insights and recommendations to leverage technology effectively. From system integration to future-proofing strategies, we are here to guide you towards sustainable growth.
</p>

          <button className='bg-[#ffffff1a] text-[#e6e6e6] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
