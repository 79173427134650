import React from 'react';
import Navbar from './components/Navbar';
import Banner from './components/Banner'; // Tama ang pangalan at kase ng letra
import Hero from './components/Hero'; // Ensure the casing matches the file name
import Offer from './components/Offer'; // Ensure the casing matches the file name
import Analytics from './components/Analytics';
import Newsletter from './components/Newsletter';
import Company from './components/Company';

function App() {
  return (
    <div>
      <Navbar />
      <Banner /> 
      <Hero />
      <Offer />
      <Analytics />
      <Company/>
      <Newsletter />
    </div>
  );
}

export default App;
