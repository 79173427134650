import React from 'react';
import videoFile from '../assets/avietho.mp4';

const LocalVideoPlayer = () => {
  return (
    <div>
      <video autoPlay muted loop>
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default LocalVideoPlayer;
