import React from 'react';
import dep21 from '../assets/dep21.jpg';
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div className='w-full bg-black py-16 px-4 relative'>
      {/* Background Image */}
      <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${dep21})`, opacity: '0.1' }} />
      {/* Content */}
      <div className='max-w-[850px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h1 className='md:text-2xl sm:text-2xl text-2xl text-[#ffffff] font-bold py-2' >
          ABOUT COMPANY
        </h1>
        <div>
          <div className='flex justify-center items-center'>
            <p className='text-[#ffffff93] text-sm'>
              Our team of young and talented social media and brand managers have the competent experience of delivering above-expectation results
              that have created successful trademark and long-lasting brand among public figures and consumer products.
              Established in 2018 in Ayala-Alabang, Muntinlupa City, Philippines, the Team has been aiming to help individual personalities,
              public figures, as well as small and medium businesses maximize their online presence through cost-effective and affordable online promotions and campaign marketing.
            </p>
          </div>
          {/* Typed Text */}
          <ReactTyped className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2' strings={["Innovate. Influence. Impact: Your Trusted Advertising Partner"]} typeSpeed={40}  backSpeed={140} loop />
        </div>
      </div>
    </div>
  );
};

export default Hero;
