import React from 'react';
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div className='text-white'>
      <div className='max-w-[850px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        
        <h1 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>
          Transforming Ideas into Impact: Where Vision Meets Results
        </h1>
        <div>
          <div className='flex justify-center items-center'>
            <p className='md:text-1xl text-xl font-bold text-gray-500'>
              Where Creativity Knows No Bounds Let Us Redefine Your Brand Story
            </p>
          </div>
          <ReactTyped className='md:text=5xl sm:text=4xl text=xl font-bold md:pl-4 pl-2' strings={["Innovate. Influence. Impact: Your Trusted Advertising Partner"]} typeSpeed={40}  backSpeed={140} loop />
        </div>
        <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>
          STARTED
        </button>
      </div>
    </div>
  );
};

export default Hero;
